import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { NavigateBeforeSharp } from '@mui/icons-material';
import '../private-class-checkout/PrivateClassCheckout.css';
import KnetPayment from '../../knet/KnetPayment';
import ReactDOM from 'react-dom';
import { axiosPost } from '../../../backend_helper/api';
import { WEB_SOCKET_URLS } from '../../../config/apiConfig';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import RenderRazorpay from '../../../components/razor-pay/RenderRazorpay';
import ErrorModal from '../../../components/error-modal/ErrorModal';

const CourseClassCheckout = () => {
    const navigate = useNavigate();
    const classList = JSON.parse(sessionStorage.getItem('GROUP_COURSE'));
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [displayRazorpay, setDisplayRazorpay] = useState(false);
    const [isError, setIsError] = useState(null);
    const [orderDetails, setOrderDetails] = useState({
        orderId: null,
        currency: null,
        amount: null
    });

    function formatDate(inputString) {
        const date = new Date(inputString);
        
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHours = (hours % 12) || 12;
        
        const formattedDate = `${day}-${month}-${year} | ${formattedHours}:${minutes}${ampm} UTC`;
        
        return formattedDate;
      }

    // let dateTime = formatDate(classList.DateTime)
   
    const handlePayment = (Status, StatusCode, data) => {
        setDisplayRazorpay(false);
        if (data) {
            let payload = {};
            payload['ClassCode'] = JSON.parse(sessionStorage.getItem('GROUP_COURSE')).ClassLink;
            payload['PaymentID'] = data.razorpay_payment_id;
    
            // axiosPost(`http://localhost:8080/SavePrivateClassPaymentDetails`, payload)
            axiosPost(WEB_SOCKET_URLS.SaveGroupCoursePaymentDetails, payload)
                .then((res) => {
                    const responseData = res.data;
                    if (StatusCode == 3) {
                        setIsError(<ErrorModal onCloseHandler={onCloseHandler} errMsg={'Transaction Failed!'} />);
                    } else {
                        navigate(`/student-course-class/${sessionStorage.getItem('LINK_SESSION')}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const onPayNowClickHandler = () => {
        const payload = {};
        payload['ClassCode'] = JSON.parse(sessionStorage.getItem('GROUP_COURSE')).ClassLink;
        
        axiosPost(WEB_SOCKET_URLS.GetTutorClassPaymentLink, payload)
            .then((res) => {
                const responseData = res.data.PrivateTutorPaymentLink;
                if (res.data.StatusCode === 200) {
                    const paymentURL = responseData?.PaymentURL

                    if (paymentURL && typeof paymentURL === 'string') {
                        // Render the PaymentPage component with the paymentHTML
                        const paymentHTML = paymentURL; // You can use paymentURL directly
                        ReactDOM.render(
                            <KnetPayment paymentHTML={paymentHTML} />,
                            document.getElementById('root')
                        );
                    } else {
                        setOrderDetails({
                            orderId: paymentURL.id,
                            amount: paymentURL.amount,
                            currency: paymentURL.currency,
                        })
                        setDisplayRazorpay(true);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onCloseHandler = () => {
		setIsError(null);
	}

    return (
        <>
            <div className='checkOutPage'>
                <div className='checkoutPageSec'>
                    <img src='./images/bg-element-1.png' alt='' className='bg-element-1' />
                    <img src='./images/bg-element-2.png' alt='' className='bg-element-2' />
                    <div className='checkOutPageInner'>
                        <Link to={`/student-course-class/${sessionStorage.getItem('LINK_SESSION')}`} className='text-btn'><NavigateBeforeSharp />  Back </Link>
                        <h2 className='classheading'>Payment</h2>
                        <div className='checkoutCard'>
                            <div className='card-body'>
                                <div className='classSec'>
                                    <h4>{classList?.CourseName}</h4>
                                    {/* <div className='durationTime'>
                                        <p><AccessTimeIcon /> Duration <span>{classList?.Duration}</span></p>
                                        <p><CalendarTodayIcon /> Date & Time <span>{new Date(classList?.DateTime).toLocaleString('en-US', { timeZone: userTimeZone, day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</span></p>
                                    </div> */}
                                </div>
                                <div className='couponSec'>
                                    <h3>Add Coupon Code</h3>
                                    <div className='promoCodeSec'>
                                        <input type="text" placeholder='Enter promo code' />
                                        <button>Apply</button>
                                    </div>
                                </div>
                                <div className='discountSec'>
                                    {/* <p>Course Fees<span>{parseInt(classList?.Price) + ' KD'}</span></p> */}
                                    <p>Course Fees<span>{parseInt(classList?.Price)} {classList?.CurrencyType}</span></p>
                                    <p>Transaction Fees <span>{ parseInt(classList?.TransactionFees)} {classList?.CurrencyType}</span></p>
                                    {/* <p>One Hour Session <span>{classList?.Price} {classList?.CurrencyType}</span></p> */}
                                    <p>Discount Applied <span>0 {classList?.CurrencyType}</span></p>
                                </div>
                            </div>
                            <div className='totalPaymentSec'>
                                <h2>Total Payment</h2>
                                {/* <h2>{classList?.Price} {classList?.CurrencyType}</h2> */}
                                <h2>{`${parseInt(classList?.Price) + parseInt(classList?.TransactionFees)} ${classList?.CurrencyType}`} </h2>
                            </div>
                            <div className='paybtnSec'>
                                <button className='paybtn' onClick={onPayNowClickHandler}>Pay Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                displayRazorpay && 
                    <RenderRazorpay
                        orderId={orderDetails.orderId}
                        amount={orderDetails.amount}
                        currency={orderDetails.currency}
                        handlePayment={handlePayment}
                    />
            }
        </>
    )
}

export default CourseClassCheckout
