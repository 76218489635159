import React from 'react';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const ErrorModal = ({onCloseHandler, errMsg, link}) => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div id="myModal" className="error-modal">
                <div className="error-modal-content">
                    <span className="error-modal-close" onClick={onCloseHandler}>
                        &times;
                    </span>
                    <div className="cis-modal-wrap">
                        <ErrorOutlineIcon color="error" />
                        <p>{errMsg}</p>
                    </div>
                    { link ?  <div className='text-center'>
                        <Button variant='text' onClick={onCloseHandler} style={{marginRight: '30px'}}>Cancel</Button>
                        <Button variant='text' onClick={() => navigate(link)}>Signup</Button>
                    </div> : ''
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default ErrorModal;