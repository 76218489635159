import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { axiosPost } from '../../backend_helper/api';
import { RAZORPAY_KEY } from '../../config/apiConfig';

// Function to load script and append it to the DOM tree.
const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      console.log('Razorpay loaded successfully');
      resolve(true);
    };
    script.onerror = () => {
      console.log('Error in loading Razorpay');
      resolve(false);
    };
    document.body.appendChild(script);
  });

const RenderRazorpay = ({ amount, currency, orderId, handlePayment }) => {

  // Load and open Razorpay checkout modal
  const displayRazorpay = async () => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      console.log('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const options = {
      key: RAZORPAY_KEY, // FIXED: Changed `keyId` to `key`
      amount: amount, // Convert to paisa
      currency,
      name: 'Bezooz',
      description: 'Top Online Tutoring Platform',
      order_id: orderId,
      handler: function (response) {
        console.log('Payment Success Response:', response);
        handlePayment('Success', '2', response);
      },
      prefill: {
        name: 'Bezooz',
        email: 'support@bezooz.com',
      },
      theme: {
        color: '#3399cc',
      },
      modal: {
        ondismiss: function (response) {
          console.log('response: ', response);
          console.log('Payment popup closed');
          handlePayment('Expired/Cancelled', {});
        },
      },
    };

    const rzp1 = new window.Razorpay(options);
    
    // Open Razorpay modal
    rzp1.open();

    // Handle modal closure
    rzp1.on('payment.failed', function (response) {
      console.log('Payment Failed Response:', response);
      handlePayment('Expired/Cancelled', '3', response);
    });
  };

  useEffect(() => {
    displayRazorpay();
  }, [amount, currency, orderId]); // FIXED: Added dependencies to re-run when props change

  return null;
};

RenderRazorpay.propTypes = {
  orderId: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  handlePayment: PropTypes.func.isRequired,
};

export default RenderRazorpay;